import Link, { type LinkProps } from "next/link";
import type React from "react";

import classNames from "classnames";

import { ArrowRightIcon } from "@heroicons/react/20/solid";

import type { AnchorHTMLAttributes } from "react";
import Loader from "../Loader";
import { Countdown } from "./Countdown";

const btnClasses = ({
  secondary,
  danger,
  small,
  medium,
  minimal,
  inline,
  className,
}: ButtonProps) => {
  return classNames("rounded cursor-pointer disabled:bg-gray-300", className, {
    "w-full block": !inline,
    "inline-block": inline,
    "text-center": !className || className.indexOf("text-left") === -1, // center by default
    "text-white bg-blue-500 active:bg-blue-800 hover:bg-blue-800":
      !secondary && !minimal && !danger,
    "text-gray-800 bg-gray-300 active:bg-gray-200 hover:bg-gray-200 disabled:opacity-50":
      secondary,
    "text-red-50 bg-red-500 active:bg-red-400 hover:bg-red-400 disabled:opacity-50":
      danger,
    "px-8 py-4": !small && !medium && !minimal,
    "px-4 py-2": medium && !minimal,
    "px-2 py-1": small && !minimal,
    "text-blue-500 active:text-blue-800 hover:text-blue-800": minimal,
    "font-bold": !minimal,
  });
};

export type ButtonProps = {
  label?: string;
  icon?: React.ReactNode;
  secondary?: boolean;
  danger?: boolean;
  small?: boolean;
  medium?: boolean;
  minimal?: boolean;
  inline?: boolean;
  loading?: boolean;
  className?: string;
  children?: React.ReactNode;
};

// strips our custom ButtonProps from the other props
// so that we don't assign invalid html attributes
// e.g. <button medium="true">
// Note: className gets stripped even though it's valid
// because it is consumed in our btnClasses helper
const withoutButtonProps = (props: any) => {
  const {
    label,
    icon,
    danger,
    secondary,
    small,
    medium,
    minimal,
    inline,
    loading,
    className,
    children,
    ...rest
  } = props;

  return rest;
};

const ButtonLink = (
  props: ButtonProps & LinkProps & AnchorHTMLAttributes<HTMLAnchorElement>,
) => (
  <Link {...withoutButtonProps(props)} className={btnClasses(props)}>
    {props.children}
  </Link>
);

export const Button = (
  props: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
) => (
  <button {...withoutButtonProps(props)} className={btnClasses(props)}>
    {props.children}
  </button>
);

export const CountdownButton = (
  props: ButtonProps &
    React.ButtonHTMLAttributes<HTMLButtonElement> & { callback: () => void },
) => {
  const { callback, ...rest } = props;
  return (
    <div className="relative">
      <Countdown callback={callback} />
      <Button {...rest} onClick={callback} />
    </div>
  );
};

// similar to the Submit loading state, but usable in other contexts
export const LoadingButton = (props: ButtonProps) => (
  <button type="submit" disabled={true} className={btnClasses(props)}>
    <div className="flex items-center justify-center">
      <span>Loading</span>{" "}
      <span className={classNames("opacity-80 inline ml-3 w-5")}>
        <Loader small />
      </span>
    </div>
  </button>
);

export const Submit = (
  props: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
) => {
  const hasLabel = props.label !== undefined; // allow for empty string to be a valid label
  return (
    <button
      type="submit"
      disabled={props.loading}
      {...withoutButtonProps(props)}
      className={btnClasses(props)}
    >
      <div className="flex items-center justify-center">
        <span>{hasLabel ? props.label : "Submit"}</span>
        {props.loading ? (
          <span
            className={classNames("opacity-80 inline w-5", {
              "ml-1": props.small,
              "ml-3": !props.small,
            })}
          >
            <Loader small />
          </span>
        ) : props.icon ? (
          props.icon
        ) : (
          <ArrowRightIcon
            className={classNames("inline w-5 h-5", {
              "ml-1": props.small,
              "ml-3": !props.small,
            })}
          />
        )}
      </div>
    </button>
  );
};

export default ButtonLink;
